var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('v-menu',_vm._b({ref:"menuRef",attrs:{"z-index":"2000","close-delay":"100","min-width":_vm.width,"max-width":_vm.width,"close-on-content-click":false,"value":_vm.value},on:{"input":_vm.input},scopedSlots:_vm._u([{key:"activator",fn:function(activator){return [_vm._t("activator",function(){return [_c('button',_vm._g(_vm._b({staticClass:"tw-p-1 tw-w-5 tw-h-5 active:tw-bg-rock-700 hover:tw-bg-rock-700 hover:tw-border hover:tw-border-rock-700 hover:tw-border-solid disabled:tw-text-rock-400 active:enabled:tw-shadow disabled:tw-cursor-not-allowed hover:enabled:tw-shadow hover:tw-shadow-button tw-rounded tw-flex tw-flex-row tw-justify-items-center tw-items-center focus-visible:tw-bg-rock-700 focus-visible:tw-outline-none",class:{ 'tw-bg-rock-700': activator.value },attrs:{"type":"button"}},'button',activator.attrs,false),activator.on),[_c('IceIcon',{staticClass:"tw-text-rock-400",attrs:{"size":"12px","icon":"fas-ellipsis"}})],1)]},null,activator)]}}],null,true)},'v-menu',{
    ..._vm.menuProps,
    offsetOverflow: _vm.menuProps?.offsetOverflow ?? true,
    offsetX: _vm.menuProps?.offsetX ?? true,
    left: _vm.openLeft,
    right: !_vm.openLeft
  },false),[_vm._t("default")],2)
}
var staticRenderFns = []

export { render, staticRenderFns }